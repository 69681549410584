import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'

import { Container } from './styles'
import Card from '../../components/Card'
import { collection, getDocs, limit, orderBy, query } from 'firebase/firestore'
import { db } from '../../data/firebase'
import { timeStampToBr } from '../../utils/dataUtils'

function Avisos() {
  const [avisos, setAvisos] = useState([])

  useEffect(() => {
    const getAvisos = async () => {
      let list = []
      try {
        const avisosCollectionRef = collection(db, 'avisos')
        const q = query(
          avisosCollectionRef,
          orderBy('timeStamp', 'desc'),
          limit(100)
        )
        const snapshot = await getDocs(q)
        snapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() })
        })
        setAvisos(list)
      } catch (error) {
        alert(error.message)
      }
    }
    getAvisos()
  }, [])

  return (
    <Container>
      <div className="content">
        <div className="title">
          <p>
            <Link to={'/'}>Home</Link>
            <IoIosArrowBack />
            Avisos
          </p>
        </div>
        {avisos.map((aviso) => {
          return (
            <Card key={aviso.id}>
              <span>Data: {timeStampToBr(aviso.timeStamp)}</span>
              <p>{aviso.descricao}</p>
            </Card>
          )
        })}
      </div>
    </Container>
  )
}

export default Avisos
