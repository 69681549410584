import styled from "styled-components";

export const Container = styled.div`
    padding: 0; 
    margin: 0 auto;
    max-width: 1280px;
`
export const Menu = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 60px;
  background: rgba(4, 93, 209, 0.6);
  padding-left: 35px;
  padding-right: 35px ;

  .img-logo {
    height: 52px;
    width: 123px;
  }

  .cidade {
    color: #fff;
    font-size: 14pt;
  }

  .menu {
    flex: 1;
    color: #fff;
    font-size: 10pt;
    text-align: center;

    ul li {
      display: inline;
      list-style: none;
    }
    li {
      margin-left: 15px;
      :hover {
        color: #e0e0e0;
      }
    }
    a {
      text-decoration: none;
      color: #fff;
      font-weight: bold;
      :hover {
        color: #e0e0e0;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    /* flex-wrap: nowrap; */
    /* justify-content: space-between;
      align-items: center; */
    height: 120px;
    padding: 0;

    .cidade {
      margin: 0;
      padding: 0;
      font-size: 10pt;
    }
    .menu {
      flex: 0;
      margin: 10px 0 0;
      padding: 0;
      font-size: 8pt;
      ul {
        padding: 0;
        margin: 0;
      }
      li {
        margin: 0 0 0 5px;
        padding: 0;
      }
    }
  }
`
export const ButtonLogin = styled.a`
  background: rgba(51, 117, 232, 0.6);
  width: 100px;
  height: 35px;
  text-align: center;
  border-radius: 20px;
  margin: 5px 15px;
  padding: 0;
  color: #fff;
  font-weight: bold;

  :hover {
    background: #ff6f80;
  }

  p {
    margin-top: 7px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 10pt;
    height: 18px;
    width: 50px;
    margin: 5px 0;
    p {
      margin-top: 2px;
      font-size: 8pt;
    }
  }
`

export default Container
