import React, { useEffect, useState } from 'react'
import { Container } from './styles'
import { IoIosArrowBack } from 'react-icons/io'
import Card from '../../components/Card'
import { Link } from 'react-router-dom'

const Servicos = () => {
  const [style, setStyle] = useState('card')

  useEffect(() => {
    const updateStyle = () => {
      if (style === 'card') {
        setTimeout(() => {
          setStyle('card show')
        }, 10)
      }
    }
    updateStyle()
  }, [style])

  return (
    <>
      <Container>
        <div className="content">
          <div className="title">
            <p>
              <Link to={'/'}>Home</Link>
              <IoIosArrowBack />
              Serviços
            </p>
          </div>

          <div className="swing">
            <div className="servicos">
              <Card className={style}>
                <h2>TRANSLADO</h2>
                <p>
                  Serviço de locomoção de cadáver, através de urnas funerárias
                  adequadas, por vias terrestre ou aérea. Importante destacar o
                  cumprimento das legislações específicas da Anvisa.
                </p>
              </Card>
              <Card className={style}>
                <h2>TANATOPRAXIA</h2>
                <p>
                  Processo de tratamento adequado de acordo com as legislações
                  da Anvisa, com acessórios, produtos e técnicos capacitados,
                  garantindo a aproximação da aparência em vida do falecido, e a
                  permanência por mais tempo no velório .
                </p>
              </Card>
              <Card className={style}>
                <h2>ORNAMENTAÇÃO</h2>
                <p>
                  ORNAMENTO NO INTERNO DA URNA AO DERREDOR DO FALECIDO COM
                  ARRANJOS DE FLORES ARTESANAIS OU NATURAIS transmitindo assim
                  uma imagem de serenidade, de acordo com o credo religioso
                  familiar.
                </p>
              </Card>

              <Card className={style}>
                <h2>ASSISTÊNCIA FUNERAL</h2>
                <p>
                  Decoração do ambiente de acordo com o credo religioso
                  familiar, oferecendo as seguintes paramentações: jarros de
                  flores, suporte para coroas, cadeiras, tendas, bebedouro,
                  garrafas de café, leite, chá, acompanhamento de abertura de
                  cova no cemitério e veículo para cortejo.
                </p>
              </Card>

              <Card className={style}>
                <h2>HIGIENIZAÇÃO</h2>
                <p>
                  Deixando a necessidade da tanatopraxia que inclui a
                  higienização de acordo com a causa morte, fazemos um processo
                  de limpeza incluindo o tamponamento, para dificultar a saída
                  de secreção, correção de incisão e maquiagem.
                </p>
              </Card>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Servicos
