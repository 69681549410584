import React, { useEffect, useState } from 'react'
import Card from '../../components/Card'
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore'

import { Container, Form, Row, Column } from './styles'
import { db } from '../../data/firebase'
import { useLocation, useNavigate } from 'react-router-dom'

function EventoCad() {
  const [evento, setEvento] = useState({})
  const navigate = useNavigate()
  const obj = useLocation().state?.obj
  const labelButton = obj ? 'Salvar' : 'Cadastrar'

  useEffect(() => {
    if (obj) setEvento(obj)
  }, [obj])

  async function handleSend(e) {
    e.preventDefault()
    try {
      if (obj) {
        const _evento = evento
        delete _evento.timeStamp
        await setDoc(doc(db, 'eventos', obj.id), _evento, { merge: true })
      } else {
        const _evento = { ...evento, timeStamp: serverTimestamp() }
        await addDoc(collection(db, 'eventos'), _evento)
      }
      return navigate('/admin/eventos')
    } catch (error) {
      alert(error.message)
    }
  }

  function handleChange(e) {
    const { name, value } = e.target
    setEvento({ ...evento, [name]: value })
  }

  return (
    <Container>
      <div className="card-title">
        <h2>Novo Evento</h2>
      </div>
      <Card style={{ paddingTop: 20 }}>
        <Form onSubmit={handleSend}>
          <Row>
            <Column>
              <label htmlFor="data">Data</label>
              <input
                type="date"
                name="data"
                value={evento.data}
                onChange={handleChange}
              />
            </Column>
            <Column>
              <label htmlFor="hora">Hora</label>
              <input
                type="time"
                name="hora"
                value={evento.hora}
                onChange={handleChange}
              />
            </Column>
          </Row>
          <label htmlFor="descricao">Descrição do Evento</label>
          <textarea
            cols={80}
            rows={10}
            name="descricao"
            value={evento.descricao}
            onChange={handleChange}
          />
          <label htmlFor="local">Local</label>
          <input
            type="text"
            name="local"
            value={evento.local}
            onChange={handleChange}
          />
          <button>{labelButton}</button>
        </Form>
      </Card>
    </Container>
  )
}

export default EventoCad
