import React from 'react'
import Container from './styles'
import { getImage } from '../../../../utils/getImage'
import { useNavigate } from 'react-router-dom'

export default function Plan({ index, plano }) {
  const navigate = useNavigate()

  const gotoPlanos = () => {
    navigate('/planos')
  }

  return (
    <>
      <Container className="zoom" onClick={gotoPlanos}>
        <div
          className="content-image"
          style={{ color: plano?.background}}
        >
          {getImage(index)}
        </div>

        <h3>{plano?.description}</h3>
        <p>
          <b>Benefícios: </b>
          <span className="benefits">{plano?.benefits}</span>
        </p>
        
      </Container>
    </>
  )
}
