import React from 'react'
import { Container, ListPlans } from './styles'
import PlanoHome from './PlanoHome'

import { planos } from '../../../data/planos'

function PlanosHome() {
  return (
    <Container id="plans">
      <div className="card-title">
        <h2>Nossos Planos</h2>
      </div>
      <ListPlans>
        {planos?.map((plano, index) => (
          <PlanoHome index={index} plano={plano} key={plano._id} />
        ))}
      </ListPlans>
    </Container>
  )
}

export default PlanosHome
