import React, { useContext } from 'react'
import { Link, Navigate, Outlet, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../contexts/auth'
import Footer from '../Footer'
import { Container, Menu } from './styles'

function LayoutAdmin() {
  const navigate = useNavigate()
  const { logout, logado } = useContext(AuthContext)
  function handleLogOut() {
    if (logout()) navigate('/')
  }
  return (
    <Container>
      <Menu>
        <img
          src="images/logo-p.png"
          title="Sistema Pamf de Auxílio Funerário"
          className="img-logo"
          alt="logo"
        />
        <div className="menu">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/admin">Admin</Link>
            </li>
            <li>
              <Link to="avisos">Avisos</Link>
            </li>
            <li>
              <Link to="eventos">Eventos</Link>
            </li>
            <li>
              <button onClick={handleLogOut}>Sair</button>
            </li>
          </ul>
        </div>
      </Menu>
      <main>
        {logado ? <Outlet /> : <Navigate to="/" /> }
      </main>
      <footer>
        <Footer />
      </footer>
    </Container>
  )
}

export default LayoutAdmin
