import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../Footer'
import MenuContainer from '../Menu'
import { Container } from './styles'

function Layout() {
  return (
    <Container>
      <MenuContainer />
      <main>
        <Outlet />
      </main>
      <footer>
        <Footer />
      </footer>
    </Container>
  )
}

export default Layout
