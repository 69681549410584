import { Timestamp } from "firebase/firestore"

export function dataBr(data) {
  const dataJs = new Date(data)
  const fmt = new Intl.DateTimeFormat('pt-BR').format(dataJs)
  return fmt
}

export function timeStampToBr(timeObj) {
  const timestamp = new Timestamp(timeObj.seconds, timeObj.nanoseconds)
  const datejs = timestamp.toDate()
  var options = { dateStyle: 'short', timeStyle: 'short' }
  const fmtDate = new Intl.DateTimeFormat('pt-BR', options).format(datejs)
  return fmtDate
}