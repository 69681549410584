import React from 'react'
import { Link } from 'react-router-dom'
import Card from '../../components/Card'

import { Container } from './styles'

function Admin() {
  return (
    <Container>
      <div className="card-title">
        <h2>Administração do Site</h2>
      </div>
      <Card>
        <ul>
          <li>
            <Link to="avisos">Cadastro de Avisos</Link>
          </li>
          <li>
            <Link to="eventos">Cadastro de Eventos</Link>
          </li>
        </ul>
      </Card>
    </Container>
  )
}

export default Admin
