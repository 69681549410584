import { initializeApp } from 'firebase/app'
// import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from '@firebase/firestore'
import { getAuth } from 'firebase/auth'


const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: 'site-pamf.firebaseapp.com',
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: 'site-pamf.appspot.com',
  messagingSenderId: '292021490590',
  appId: process.env.REACT_APP_APP_ID,
  measurementId: 'G-FC0GNKC2EL',
}

export const app = initializeApp(firebaseConfig)
// const analytics = getAnalytics(app)
export const db = getFirestore(app)
export const auth = getAuth(app)


