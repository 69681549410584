import React, { useEffect, useState } from 'react'
import { CardGlass, CardP, CardSubTitle, CardTitle, Container } from './styles'
import { IoIosArrowBack } from 'react-icons/io'
import { getImage } from '../../utils/getImage'

import { Link } from 'react-router-dom'

import { planos } from '../../data/planos'

const Planos = () => {
  const [style, setStyle] = useState('')

  useEffect(() => {
    const updateStyle = () => {
      if (style === '') {
        setTimeout(() => {
          setStyle('show')
        }, 10)
      }
    }
    updateStyle()
  }, [style])

  return (
    <>
      <Container>
        <div className="content">
          <div className="title">
            <p>
              <Link to={'/'}>Home</Link>
              <IoIosArrowBack />
              Planos
            </p>
          </div>

          <div className="planos">
            {planos?.map((plano) => {
              return (
                <CardGlass
                  bgColor={plano.background}
                  textColor={plano.color}
                  className={style}
                >
                  <CardTitle>
                    {getImage(plano._id)} {plano.description}
                  </CardTitle>

                  <CardSubTitle>Benefícios</CardSubTitle>
                  <CardP>{plano.benefits}</CardP>
                  <CardSubTitle>Beneficiários</CardSubTitle>
                  <CardP>{plano.beneficiares}</CardP>
                  <CardSubTitle>Prestação de serviço</CardSubTitle>
                  <CardP>{plano.service}</CardP>
                  <CardSubTitle>Comodidade</CardSubTitle>
                  <CardP>{plano.convenience}</CardP>
                </CardGlass>
              )
            })}
          </div>
        </div>
      </Container>
    </>
  )
}

export default Planos
