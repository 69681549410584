export default function validateForm({ name, email, msg }) {
	if (!name.trim()) {
		return 'Nome não informado';
	}
	const regex =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (!email) {
		return 'Email não informado';
	} else if (regex.test(email.toLocalLowerCase)) {
		return 'E-mail inválido';
	}
	if (!msg) {
		return 'Mensagem não informada';
	} 
	return null;
}