import styled from "styled-components";

export const Container = styled.div`
  margin: 0;
  background: #fff;
  img {
    /* width: 500px; */
  }

  .slide-container {
    display: flex;
    height: 100%;
  }
  .slide-image {
    object-fit: fill; 
    width: 100%;
  }
`
export const Paineis = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
`