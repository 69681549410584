import styled from "styled-components";

export const Container = styled.div`
    background: #fff;
    height: 100%;
    padding-top: 20px;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 10px;

    h2 {
      font-size: 14pt;
      color: #757575;
      margin-left: 10px;
    }

    .card-title {
      display: flex;
      align-items: center;
      color: #757575;
      margin-bottom: 8px;

      .image{
        font-size: 20pt;
      }
    }
`
export const ListPlans = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
  `

export default Container