import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'
import {
  Container,
  FormColumn,
  FormWrapper,
  FormInput,
  FormSection,
  FormRow,
  FormLabel,
  FormInputRow,
  FormMessage,
  FormButton,
  FormTitle,
  FormTextArea,
} from './styles'

import validateForm from './validateForm'
import apiEmail from '../../services/apiEmail'
import { BeatLoader } from 'react-spinners'

export default function Contato() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [msg, setMsg] = useState('')
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    const resultError = validateForm({ name, email, msg })

    if (resultError !== null) {
      setLoading(false)
      setError(resultError)
      return
    }

    const resp = await apiEmail.enviar(name, email, msg)
    if (resp.ok) setSuccess(resp.msg)
    else setError(resp.msg)
    setLoading(false)
    setName('')
    setEmail('')
    setMsg('')
    setError(null)
  }

  const messageVariants = {
    hidden: { y: 30, opacity: 0 },
    animate: { y: 0, opacity: 1, transition: { delay: 0.2, duration: 0.4 } },
  }

  const formData = [
    {
      label: 'Nome',
      value: name,
      onChange: (e) => setName(e.target.value),
      type: 'text',
    },
    {
      label: 'E-mail',
      value: email,
      onChange: (e) => setEmail(e.target.value),
      type: 'email',
    },
  ]
  return (
    <FormSection>
      <Container>
        <div className="title">
          <p>
            <Link to={'/'}>Home</Link>
            <IoIosArrowBack />
            Contato
          </p>
        </div>
        <FormRow>
          <FormColumn small>
            <FormTitle>Envie sua mensagem</FormTitle>
            <FormWrapper onSubmit={handleSubmit}>
              {formData.map((el, index) => (
                <FormInputRow key={index}>
                  <FormLabel>{el.label}</FormLabel>
                  <FormInput
                    type={el.type}
                    placeholder={`${el.label.toLocaleLowerCase()}`}
                    value={el.value}
                    onChange={el.onChange}
                  />
                </FormInputRow>
              ))}

              <FormInputRow>
                <FormLabel>Mensagem</FormLabel>
                <FormTextArea
                  placeholder="Mensagem..."
                  cols="80"
                  rows="5"
                  value={msg}
                  onChange={(e) => setMsg(e.target.value)}
                />
              </FormInputRow>

              <FormButton type="submit">
                <BeatLoader loading={loading} />
                Enviar
              </FormButton>
            </FormWrapper>
            {error && (
              <FormMessage
                variants={messageVariants}
                initial="hidden"
                animate="animate"
                error
              >
                {error}
              </FormMessage>
            )}
            {success && (
              <FormMessage
                variants={messageVariants}
                initial="hidden"
                animate="animate"
              >
                {success}
              </FormMessage>
            )}
          </FormColumn>
        </FormRow>
      </Container>
    </FormSection>
  )
}
