import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Layout from './components/Layout'
import LayoutAdmin from './components/LayoutAdmin'
import Admin from './pages/Admin'
import EventoAdm from './pages/Admin/EventoAdm'
import EventoCad from './pages/Admin/EventoCad'
import AvisoAdm from './pages/Admin/AvisoAdm'
import AvisoCad from './pages/Admin/AvisoCad'
import Empresa from './pages/Empresa'
import Eventos from './pages/Eventos'

import Home from './pages/Home'
import Avisos from './pages/Avisos'
import Login from './pages/Login'
import Planos from './pages/Planos'
import Servicos from './pages/Servicos'
import Contato from './pages/Contato'

const Rotas = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />} path="/">
          <Route index element={<Home />} />
          <Route path="empresa" element={<Empresa />} />
          <Route path="planos" element={<Planos />} />
          <Route path="servicos" element={<Servicos />} />
          <Route path="eventos" element={<Eventos />} />
          <Route path="avisos" element={<Avisos />} />
          <Route path="login" element={<Login />} />
          <Route path="contato" element={<Contato />} />
        </Route>
        <Route element={<LayoutAdmin />} path="/admin">
          <Route index element={<Admin />} />
          <Route path="avisos" element={<AvisoAdm />} />
          <Route path="avisos/novo" element={<AvisoCad />} />
          <Route path="eventos" element={<EventoAdm />} />
          <Route path="eventos/novo" element={<EventoCad />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Rotas
