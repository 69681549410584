import styled from 'styled-components'

export const Container = styled.div`
  padding: 0px;
  margin: 0 auto;
  max-width: 1280px;

  .content {
    background: #fff;
    padding: 10px 35px;
    min-height: 90vh;
  }

  .title {
    display: flex;

    h2 {
      margin-top: 10px;
      margin-bottom: 10px;
      color: #fff;
    }

    .icon {
      font-size: 28px;
      color: #cacaca;
    }

    .icon:hover {
      cursor: pointer;
    }
  }

  .planos {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    justify-content: center;
    color: #fff;
    align-items: baseline;
  }

  .row {
    background: red;
  }

  .padrao {
    background-color: #2758e9;

    h2 {
      color: #fff;
    }
  }
`
export const CardGlass = styled.div`
  --color-in: ${({ bgColor }) => (bgColor ? bgColor + '80' : '#000')};
  --color-bg: ${({ bgColor }) => (bgColor ? bgColor : '#000')};
  color: ${({ textColor }) => (textColor ? textColor : '#000')};
  border-radius: 20px;
  padding: 1rem;

  background: #2193b0; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    var(--color-in),
    var(--color-bg)
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    var(--color-in),
    var(--color-bg)
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  opacity: 0;
  padding: 0.8rem;
  width: 300px;
  min-height: 630px;

  /* border-radius: 10px; */
  transform: rotateX(-90deg);
  transition: all 0.5s cubic-bezier(0.36, -0.64, 0.34, 1.76);

  &.show {
    opacity: 1;
    transform: none;
    transition: all 0.5s cubic-bezier(0.36, -0.64, 0.34, 1.76);
  }
`
export const CardTitle = styled.h2`
  font-size: 14pt;
`
export const CardSubTitle = styled.h3`
  font-size: 12pt;
`
export const CardP = styled.p`
  font-size: 12pt;
`

export default Container
