import styled from 'styled-components'

export const Titulo = styled.div`
  display: flex;
  padding: 10px 35px;

  p {
    font-size: 12pt;
  }

  .icon {
    font-size: 28px;
    color: #cacaca;
  }

  .icon:hover {
    cursor: pointer;
  }
`
export const Foto = styled.div`
  .card {
    
  }
  .swing .card {
    margin: 0;
    padding: 0;
    opacity: 0;
    
    transform: rotateX(-90deg);
    transition: all 0.5s cubic-bezier(0.36, -0.64, 0.34, 1.76);
    h3 {
      font-size: 12pt;
      color: #108ad1;
    }
    p {
      font-size: 12pt;
    }
  }
  
  .swing .show {
    opacity: 1;
    transform: none;
    transition: all 0.5s cubic-bezier(0.36, -0.64, 0.34, 1.76);
  }
  
  .foto {
    max-width: 1280px;
    object-fit: cover;
    
    width: 100vw;
  }
`

export const Container = styled.div`
  padding: 0px;
  margin: 0 auto;
  max-width: 1280px;

  h2 {
    font-size: 14pt;
    color: #0a1944;
  }

  p {
    font-size: 12pt;
  }

  .content {
    background: #fff;
    padding: 10px 35px;
  }

  .valores {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .card-emp {
    max-width: 340px;
    background: url('/images/fundo4.jpg') 100%;
  }

  .card-emp h2 {
    color: #ffee00;
  }
  .card-emp p {
    color: #ffffff;
    margin-left: 20px;
  }

  .card-emp ul li {
    list-style-image: url('/images/borboleta.png');
    padding: 3px;
    color: #fff;
  }

  .card img {
    height: 400px;
  }
`
export default Container
