import styled from 'styled-components'

export const Container = styled.div`
  padding: 0px;
  margin: 0 auto;
  max-width: 1280px;

  .content {
    background: #fff;
    padding: 10px 35px;
    min-height: 90vh;

    p {
      color: #e6e6e6;
    }
  }

  .title {
    display: flex;

    h2 {
      margin-top: 0;
      margin-left: 10px;
    }

    .icon {
      font-size: 28px;
      color: #cacaca;
    }

    .icon:hover {
      cursor: pointer;
    }
  }

  .servicos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    color: #fff;
  }

  .card {
    opacity: 0;
    background: url('./images/fundo6.jpg') 100%;
    width: 300px;
    transform: rotateX(-90deg);
    transition: all 0.5s cubic-bezier(0.36, -0.64, 0.34, 1.76);
    h2 {
      font-size: 14pt;
      color: #fdfdfd;
    }
    h3 {
      font-size: 12pt;
      color: #646464;
    }
    p {
      font-size: 12pt;
    }
  }

  .show {
    opacity: 1;
    transform: none;
    transition: all 0.5s cubic-bezier(0.36, -0.64, 0.34, 1.76);
  }
`
export default Container
