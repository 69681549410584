import React, { useEffect, useState } from 'react'
import { Container, Foto, Titulo } from './styles'
import { IoIosArrowBack } from 'react-icons/io'
import Card from '../../components/Card'
import { Link } from 'react-router-dom'

const Empresa = () => {
  const [style, setStyle] = useState('card')

  useEffect(() => {
    const updateStyle = () => {
      if (style === 'card') {
        setTimeout(() => {
          setStyle('card show')
        }, 10)
      }
    }
    updateStyle()
  }, [style])

  return (
    <>
      <Titulo>
        <p>
          <Link to={'/'}>Home</Link>
          <IoIosArrowBack />
          Empresa
        </p>
      </Titulo>

      <Container>
          <Foto>
            <div className="swing">
              <div className={style}>
                <img
                  className="foto"
                  src="/images/fotoempresa.jpeg"
                  alt="prédio Plano Pamf"
                />
              </div>
            </div>
          </Foto>
        <div className="content">
          <div className="valores">
            <Card className="card-emp">
              <h2>Missão Pamf</h2>
              <p>
                Respeitar a vida, proporcionando atendimento diferenciado com
                excelência, responsabilidade e comprometimento, oferecendo aos
                nossos clientes segurança e qualidade, através de práticas
                legais, transparecendo credibilidade na prestação de serviços
                póstumos.
              </p>
            </Card>
            <Card className="card-emp">
              <h2>Visão Pamf</h2>
              <p>
                Ser reconhecida regionalmente como uma empresa de excelência em
                serviços póstumos, através do aperfeiçoamento contínuo de nossos
                atendimentos.
              </p>
            </Card>
            <Card className="card-emp">
              <h2>Valores Pamf</h2>
              <ul>
                <li>Dignidade humana</li>
                <li>Responsabilidade sustentável</li>
                <li>Trabalho em equipe</li>
                <li>Respeito as legislações vigentes</li>
                <li>Trabalho em equipe - Compromisso e excelência na prestação de serviços póstumos.</li>
              </ul>
            </Card>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Empresa
