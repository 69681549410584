import React, { createContext, useEffect, useState } from 'react'
import { signOut, signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../data/firebase'

export const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)

  useEffect(() => {
    const loadStored = () => {
      const sessionUser = sessionStorage.getItem('@auth:user')
      if (sessionUser) {
        setUser(sessionUser)
      }
    }
    loadStored()
  }, [])

  const login = async (email, senha) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        senha
      )
      const user = userCredential.user
      setUser(userCredential.user)
      sessionStorage.setItem('@auth:user', JSON.stringify(user))
      return true
    } catch (error) {
      alert(error.message)
      return false
    }
  }

  const logout = async () => {
    try {
      await signOut(auth)
      setUser(null)
      sessionStorage.clear()
      return true
    } catch (error) {
      alert(error.message)
      return false
    }
  }
  return (
    <AuthContext.Provider value={{ login, logout, logado: !!user }}>
      {children}
    </AuthContext.Provider>
  )
}
