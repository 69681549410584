import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Card from '../../components/Card'
import { db } from '../../data/firebase'
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  deleteDoc,
  doc,
} from 'firebase/firestore'

import { Container } from './styles'

function EventoAdm() {
  const navigate = useNavigate()
  const [data, setData] = useState([])

  useEffect(() => {
    const getEventos = async () => {
      let list = []
      try {
        const eventosCollectionRef = collection(db, 'eventos')
        const q = query(
          eventosCollectionRef,
          orderBy('timeStamp', 'desc'),
          limit(100)
        )
        const snapshot = await getDocs(q)
        snapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() })
        })
        setData(list)
      } catch (error) {
        alert(error.message)
      }
    }
    getEventos()
  }, [])

  function handleNovo() {
    navigate('novo')
  }

  async function handleDelete(id) {
    try {
      await deleteDoc(doc(db, 'eventos', id))
      setData(data.filter((item) => item.id !== id))
    } catch (error) {
      alert(error.message)
    }
  }

  function handleEditar(evento) {
    navigate('novo', { state: { obj: evento } })
  }

  return (
    <Container>
      <div className="card-title">
        <h2>Eventos</h2>
      </div>
      <Card style={{ paddingTop: 20 }}>
        <button style={{ marginBottom: 20 }} onClick={handleNovo}>
          Novo
        </button>
        <table border={1}>
          <thead>
            <tr>
              <th>#</th>
              <th>Data</th>
              <th>Hora</th>
              <th>Descrição</th>
              <th>Local</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {data.map((evento, index) => (
              <tr key={evento.id}>
                <td>{index + 1}</td>
                <td>{evento.data}</td>
                <td>{evento.hora}</td>
                <td>{evento.descricao}</td>
                <td>{evento.local}</td>
                <td>
                  <button onClick={() => handleEditar(evento)}>Editar</button>
                  <button onClick={() => handleDelete(evento.id)}>
                    Excluir
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>
    </Container>
  )
}

export default EventoAdm
