import React, { useEffect, useState } from 'react'
import Card from '../../components/Card'
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore'

import { Container, Form } from './styles'
import { db } from '../../data/firebase'
import { useLocation, useNavigate } from 'react-router-dom'

function AvisoCad() {
  const [aviso, setAviso] = useState({})
  const navigate = useNavigate()
  const obj = useLocation().state?.obj
  const labelButton = obj ? 'Salvar' : 'Cadastrar'

  useEffect(() => {
    if (obj) setAviso(obj)
  }, [obj])

  async function handleSend(e) {
    e.preventDefault()
    try {
      if (obj) {
        const _aviso = aviso
        delete _aviso.timeStamp
        await setDoc(doc(db, 'avisos', obj.id), _aviso, { merge: true })
      } else {
        const _aviso = { ...aviso, timeStamp: serverTimestamp() }
        await addDoc(collection(db, 'avisos'), _aviso)
      }
      return navigate('/admin/avisos')
    } catch (error) {
      alert(error.message)
    }
  }

  function handleChange(e) {
    const { name, value } = e.target
    setAviso({ ...aviso, [name]: value })
  }

  return (
    <Container>
      <div className="card-title">
        <h2>{obj?'Novo Aviso':'Editar Aviso'}</h2>
      </div>
      <Card style={{ paddingTop: 20 }}>
        <Form onSubmit={handleSend}>
          <label htmlFor="descricao">Aviso</label>
          <textarea
            cols={80}
            rows={10}
            name="descricao"
            value={aviso.descricao}
            onChange={handleChange}
          />
         
          <button>{labelButton}</button>
        </Form>
      </Card>
    </Container>
  )
}

export default AvisoCad
