import styled from "styled-components"

const Container = styled.div`
    max-width: 1280px;
    margin: 0 auto;
    background: #212121;
    padding-top: 10px;
    padding-bottom: 10px;

    .footer {
      font-size: 10pt;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      color: #e0e0e0;
      padding-left: 35px;      
      padding-right: 35px;
    }

    h3 {
      margin:0;
      font-size: 10pt;
      color: #e0e0e0;
    }
    a {
      text-decoration: none;
      font-weight: bold;
      color: #cccccc
    }
    .center {
      text-align: center;
    } 

    ul {
      list-style: none;
      padding-left: 10px;
    }   

    .phones {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .row {
      flex: 1;
      height: 200px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-width: 260px;
      max-width: 400px;
      padding-bottom: 1rem;
    }

    .copyright {
      color: #cccccc;
      font-size: 8pt;
 
    }
`
export const SpaceFooter = styled.div`
  height: 300px;
`

export default Container