import {CgWorkAlt} from 'react-icons/cg'
import {RiVipCrownLine, RiVipDiamondLine, RiHeartLine} from 'react-icons/ri'
import {AiOutlineSetting} from 'react-icons/ai'

export function getImage(index) {
    switch(index){
      case 0: return (
          <AiOutlineSetting className="image"/>
        )
      case 1: return (
        <RiHeartLine  className="image" />
      )
      case 2: return (
        <CgWorkAlt  className="image" />
        )
      case 3: return (
        <RiVipCrownLine  className="image" />
        )
      default: return (
        <RiVipDiamondLine  className="image" />
        )
    }
};

export const TOKEN_KEY = 'PamfSite_token'