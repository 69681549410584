import React, { useContext, useState } from 'react'
import Card from '../../components/Card'
import { Input, Button, Form, CardTitulo, CardSubTitulo} from './styles'
import { Navigate } from 'react-router-dom'
import { AuthContext } from '../../contexts/auth'

export default function Login() {
  const { login, logado } = useContext(AuthContext)

  const [dados, setDados] = useState({
    email: '',
    senha: '',
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    if (login(dados.email, dados.senha)) {
      return <Navigate to="/admin" />
    }
  }

  const handleChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target
    setDados({ ...dados, [name]: value })
  }

  if (!logado) {
    return (
      <Card>
        <CardTitulo>Login</CardTitulo>
        <Form onSubmit={handleSubmit}>
        <CardSubTitulo>Informe e-mail e senha para acesso ao painel de administração</CardSubTitulo>
          <Input
            type="email"
            name="email"
            placeholder='E-mail'
            value={dados.email}
            onChange={handleChange}
          />
          <Input
            type="password"
            name="senha"
            placeholder='Senha'
            value={dados.senha}
            onChange={handleChange}
          />
          <Button>Entrar</Button>
        </Form>
      </Card>
    )
  } else {
    return <Navigate to="/admin" />
  }
}
