import styled from 'styled-components'

export const Container = styled.div`
  padding: 0px;
  margin: 0 auto;
  max-width: 1280px;

  h2 {
    font-size: 12pt;
    color: #108ad1;
    margin-bottom: 0;
  }

  p {
    font-size: 12pt;
    color: #818181;
    margin-top: 0.4rem;
  }

  span {
    margin: 0;
    font-weight: bold;
    font-size: 8pt;
    color:  #b0b0b0
  }

  .content {
    background: #fff;
    padding: 10px 35px;
  }

  .title {
    display: flex;

    h2 {
      margin-top: 0;
      margin-left: 10px;
    }

    .icon {
      font-size: 28px;
      color: #cacaca;
    }

    .icon:hover {
      cursor: pointer;
    }
  }

  .valores {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .card-emp {
    max-width: 340px;
    background: url('/images/fundo4.jpg') 100%;
  }

  .card-emp h2 {
    color: #ffee00;
  }
  .card-emp p {
    color: #ffffff;
    margin-left: 20px;
  }

  .card-emp ul li {
    list-style-image: url('/images/borboleta.png');
    padding: 3px;
    color: #fff;
  }

  .card {
    padding-top: 20px;
  }
  .card img {
    height: 400px;
  }
  .swing .card {
    height: 440px;
    opacity: 0;
    transform: rotateX(-90deg);
    transition: all 0.5s cubic-bezier(0.36, -0.64, 0.34, 1.76);
    h3 {
      font-size: 12pt;
      color: #108ad1;
    }
    p {
      font-size: 12pt;
    }
  }

  .swing .show {
    opacity: 1;
    transform: none;
    transition: all 0.5s cubic-bezier(0.36, -0.64, 0.34, 1.76);
  }

  .foto {
    width: 100%;
    height: auto;
  }
`
export default Container
