import React, { useEffect, useState } from 'react'
import { collection, getDocs, limit, orderBy, query } from 'firebase/firestore'
import { db } from '../../../data/firebase'
import { Container, Wrapper } from './styles'
import { Link } from 'react-router-dom'

function EventosHome() {
  const [eventos, setEventos] = useState([])

  useEffect(() => {
    const getEventos = async () => {
      let list = []
      try {
        const eventosCollectionRef = collection(db, 'eventos')
        const q = query(
          eventosCollectionRef,
          orderBy('timeStamp', 'desc'),
          limit(5)
        )
        const snapshot = await getDocs(q)
        snapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() })
        })
        setEventos(list)
      } catch (error) {
        alert(error.message)
      }
    }
    getEventos()
  }, [])

  return (
        <Wrapper id="eventos">
            <div className="card-title">
              <h2>Eventos</h2>
            </div>
            <Container>
              {eventos?.map((evento, index) => (
                <div key={index} className="row">
                  <h3>{evento?.titulo}</h3>
                  <span>Data: {evento?.data + '  ' + evento.hora}</span>
                  <p>{evento?.descricao}</p>
                </div>
              ))}
              <Link to="/eventos">Veja mais...</Link>

            </Container>
        </Wrapper>
     
  )
}

export default EventosHome
