import React from 'react'
import Container, { SpaceFooter } from './styles'
import { IoLogoWhatsapp } from 'react-icons/io'

export default function Footer() {
  return (
    <>
      <Container id="footer">
        <div className="footer">
          <div className="row">
            <div>
              <h3>Endereço</h3>
              <address>
                <p>
                  Rua Arlindo Nogueira, número 417, Água Branca <br />
                  Uruçuí-PI
                </p>
              </address>
            </div>
            <div className="copyright">           
                Copyright &copy; 2022 Desenvolvido por:{' '}
                <a href="mailto:contato@luizsistemas.com.br">Lukas Sistemas</a>             
            </div>
          </div>

          <div className="row">
            <h3>Plantão 24 horas</h3>
            <h4>Filiais</h4>
            <div className="phones">
              <div>Sebastião Leal-PI</div>
              <div>(89) 99426-2048</div>
            </div>
            <div className="phones">
              <div>Porto Alegre-PI</div>
              <div>(89) 99429-4072</div>
            </div>
            <div className="phones">
              <div>Antônio Almeida-PI</div>
              <div>
                (89) 3544-1884 <IoLogoWhatsapp title="Whatsapp" />
              </div>
            </div>
            <div className="phones">
              <div>Benedito Leite-MA</div>
              <div>
                (89) 3544-1884 <IoLogoWhatsapp title="Whatsapp" />
              </div>
            </div>
            <h4>Matriz</h4>
            <div className="phones">
              <div>Uruçuí-PI</div>
              <div>0800 8836212</div>
              <div>
                (89) 3544-1884 <IoLogoWhatsapp title="Whatsapp" />
              </div>
            </div>
          </div>
        </div>
      </Container>
      <SpaceFooter />
    </>
  )
}
