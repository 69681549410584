import React, { useEffect, useState } from 'react'
import { collection, getDocs, limit, orderBy, query } from 'firebase/firestore'
import { db } from '../../../data/firebase'
import { Container, Wrapper } from './styles'
import { Link } from 'react-router-dom'
import { timeStampToBr } from '../../../utils/dataUtils'

function AvisosHome() {
  const [avisos, setAvisos] = useState([])

  useEffect(() => {
    const getAvisos = async () => {
      let list = []
      try {
        const avisosCollectionRef = collection(db, 'avisos')
        const q = query(
          avisosCollectionRef,
          orderBy('timeStamp', 'desc'),
          limit(5)
        )
        const snapshot = await getDocs(q)
        snapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() })
        })
        setAvisos(list)
      } catch (error) {
        alert(error.message)
      }
    }
    getAvisos()
  }, [])

  return (
    <Wrapper id="avisos">
      <div className="card-title">
        <h2>Avisos</h2>
      </div>
      <Container>
        {avisos?.map((aviso, index) => (
          <div key={index} className="row">
            <h3>{aviso?.titulo}</h3>
            <span>Data: {timeStampToBr( aviso?.timeStamp)}</span>
            <p>{aviso?.descricao}</p>
          </div>
        ))}
        <Link to="/avisos">Veja mais...</Link>
      </Container>
    </Wrapper>
  )
}

export default AvisosHome
