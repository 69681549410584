import React from 'react'
import Container from './styles'
import { Link } from 'react-router-dom'

export default function MenuContainer() {
  return (
    <>
      <Container>
        <img
          src="images/novo-logo.png"
          title="Sistema Pamf de Auxílio Funerário"
          className="img-logo"
          alt='logo pamf'
        />
        <div className="menu">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/empresa">Empresa</Link>
            </li>
            <li>
              <Link to="/planos">Planos</Link>
            </li>
            <li>
              <Link to="/servicos">Serviços</Link>
            </li>
            <li>
              <Link to="/eventos">Eventos</Link>
            </li>
            <li>
              <Link to="/avisos">Avisos</Link>
            </li>
            <li>
             <Link to="/contato">Contato</Link>
            </li>
            <li>
              <Link to="/login">Login</Link>
            </li>
          </ul>
        </div>
        <span className="cidade">Uruçuí-PI</span>
      </Container>
    </>
  )
}
