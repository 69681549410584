import styled from 'styled-components'

export const Wrapper = styled.div`
  flex: 1;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 40px;

  h2 {
    font-size: 14pt;
    color: #757575;
    margin-left: 10px;
  }

  a {
    font-size: 9pt;
    color: #00b0ff;
    font-weight: bold;
    text-decoration: none;
    text-align: end;
  }
  
`
export const Container = styled.div`
  padding: 10px;
  margin-bottom: 0.5rem;
  min-width: 200px;
  min-height: 250px;
  
  border: 1px solid #e0e0e0;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05), 0 3px 3px 0 rgba(0, 0, 0, 0.05);
  background-image: linear-gradient(120deg, white, white 60%, #f0f9ff);

  h3 {
    font-weight: 400;
    padding: 0;
    margin: 0;
    font-size: 12pt;
    color: #117aee;
  }

  span {
    font-size: 8pt;
    color: #f3800c;
  }

  p {
    font-size: 10pt;
    color: #6d6d6d;
    margin: 0;
    padding: 0;
  }

  .row {
    margin-bottom: 0.5rem;
  }
`
