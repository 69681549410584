import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'

import { Container } from './styles'
import Card from '../../components/Card'
import { collection, getDocs, limit, orderBy, query } from 'firebase/firestore'
import { db } from '../../data/firebase'
import { dataBr } from '../../utils/dataUtils'

function Eventos() {
  const [eventos, setEventos] = useState([])

  useEffect(() => {
    const getEventos = async () => {
      let list = []
      try {
        const eventosCollectionRef = collection(db, 'eventos')
        const q = query(
          eventosCollectionRef,
          orderBy('timeStamp', 'desc'),
          limit(100)
        )
        const snapshot = await getDocs(q)
        snapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() })
        })
        setEventos(list)
      } catch (error) {
        alert(error.message)
      }
    }
    getEventos()
  }, [])

  return (
    <Container>
      <div className="content">
        <div className="title">
          <p>
            <Link to={'/'}>Home</Link>
            <IoIosArrowBack />
            Eventos
          </p>
        </div>
        {eventos.map((evento) => {
          return (
            <Card key={evento.id}>
              <h2>{evento.titulo}</h2>
              <span>
                Data: {dataBr(evento.data)} Hora: {evento.hora}
              </span>
              <p>
                {evento.descricao}
              </p>
            </Card>
          )
        })}
      </div>
    </Container>
  )
}

export default Eventos
