import React from 'react'
import { Zoom } from 'react-slideshow-image'
import { Container, Paineis } from './styles'

import 'react-slideshow-image/dist/styles.css'
import Planos from './PlanosHome'
import EventosHome from './EventosHome'
import AvisosHome from './AvisosHome'

const images = ['/images/Capa1.jpg', '/images/Capa2.png']

const zoomInProperties = {
  indicators: false,
  scale: 1.4,
}

function Home() {
  return (
    <>
      <Container>
        <Zoom {...zoomInProperties} duration={10000}>
          {images.map((each, index) => (
            <div key={index} className="slide-container">
              <img className="slide-image" src={each} alt="slide" />
            </div>
          ))}
        </Zoom>
        <Planos />
        <Paineis>
          <AvisosHome />
          <EventosHome />
        </Paineis>
      </Container>
    </>
  )
}

export default Home
