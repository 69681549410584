import styled from 'styled-components'

const Card = styled.div`
  margin: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  border: 1px solid #f0f9ff;
  box-shadow: 0 3px 3px 0 rgba(086, 094, 100, 0.05), 0 3px 3px 0 rgba(086, 094, 100, 0.05);
  background-image: linear-gradient(120deg, white, white 50%,  #f0f9ff);
  color: #757575;
`
export default Card
