
import styled from "styled-components";

export const LeftContainer = styled.div`
  flex: 1;
  text-align: center;
  
  .content-image{
    margin-top: 130px;
  }

  .image {
    font-size: 40pt;
    color: #fff;
    margin-top: 10px;
    padding: 0;
  }
`
export const RightContainer = styled.div`
  flex: 1;
  font-size: 10pt;
  margin-left: 20px;
  color: #212121;

  h3 {
    font-size: 14pt;
  }
`

const Container = styled.div`
  flex: 1;
  height: 230px;
  padding: 15px;
  min-width: 140px;
  max-width: 200px;
      
  border: 1px solid #e0e0e0;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05), 0 3px 3px 0 rgba(0, 0, 0, 0.05);
  background-image: linear-gradient(120deg, white, white 60%, #f0f9ff); 
  transition: transform .2s;

  :hover {
    transform: scale(1.05);
    transition: transform .2s;
  }

  .content-image{
    display: flex;
  }

  .image {
    flex: 1;
    font-size: 20pt;
    margin-top: 10px;
    padding: 0;
  }

  a {
    font-size: 9pt;
    color: #00b0ff;
    font-weight: bold;
    text-decoration: none;
    text-align: end;
  }

  b {
    color: #757575;
  }

  h3 {
    text-align: center;
    margin-top: 10px;
    padding: 0;
    font-size: 12pt;
    color: #424242;
  }

  .benefits {
    font-size: 10pt;
    color: #757575;
  }
  
  @media only screen and (max-width: 1024px) {
    /* max-width: 200px; */
  }
`
export const ModalContainer = {
  overlay: {
    backgroundColor: 'rgba(33, 33, 33, 0.5)'
  },
  content: {
    display: 'flex',
    background: "url('./images/back-plans.png') no-repeat 52%", 
    height: '500px',
    width: '500px',
    margin: 'auto',
    padding: '0',
    color: '#fff',
    fontFamily: 'roboto, arial, sans',
  }
}


export default Container
