export const planos = [
  {
    _id: 1,
    description: 'Plano Padrão',
    benefits: `Cobertura de 10% em consultas e exames, 
      2 dias gratuitos em pensão em Teresina/PI.`,
    beneficiares: `Esposa(o), 
      filhos solteiros, 
      2 dependentes economicamente sendo eles pai e mãe, sogro(a) ou netos.`,
    service: `Urna mortuária padrão, conjunto completo de roupas, ornamentação
      de flores artesanal, Higienização do corpo, 
      translado de 100 km (50 ida e 50 volta).`,
    convenience:
      'Assistência funeral para inteira comodidade, de acordo ao credo religioso.',
    valor: 0,
    background: '#2758E9',
    color: '#fff',
  },
  {
    _id: 2,
    description: 'Plano Especial',
    benefits: `Cobertura de 10% em consultas e exames, 
      2 dias gratuitos em pensão em Teresina/PI.`,
    beneficiares: `
      Esposa(o), filhos solteiros, dois dependentes economicamente sendo 
      eles pai e mãe, sogro(a) ou netos.`,
    service: `Urna mortuária padrão,
    conjunto completo de roupas, ornamentação de flores artesanal
    Higienização do corpo, 50% de desconto no valor do tanatopraxia, 
    translado de 150 km (75 ida e 75 volta).`,
    convenience: `50 lembrancinhas, 
      visita de 7º dia, 
      comodidade em todo o período do evento fúnebre, de acordo ao credo religioso`,
    valor: 0,
    background: '#285946',
    color: '#fff',
  },
  {
    _id: 3,
    description: 'Plano Executivo',
    benefits: `Cobertura de 18% em consultas e exames, 
      2 dias gratuitos em pensão em Teresina/PI.`,
    beneficiares: `
      Esposa(o), filhos solteiros, dois dependentes economicamente sendo 
      eles pai e mãe, sogro(a) ou netos.`,
    service: `Urna mortuária padrão,
    conjunto completo de roupas, ornamentação de flores artesanal
    Higienização do corpo, tanatopraxia, cova simples no cemitério local, 
    translado de 200 km (100 ida e 100 volta).`,
    convenience: `100 lembrancinhas, 
      visita de 7º dia, 
      comodidade em todo o período do evento fúnebre, de acordo ao credo religioso`,
    valor: 0,
    background: '#e67a2b',
    color: '#fff',
  },
  {
    _id: 4,
    description: 'Plano Ouro',
    benefits: `Cobertura de 30% em consultas e exames, 
      2 dias gratuitos em pensão em Teresina/PI.`,
    beneficiares: `
      Esposa(o), filhos solteiros, dois dependentes economicamente sendo 
      eles pai e mãe, sogro(a) ou netos.`,
    service: `Urna mortuária Luxo,
    conjunto completo de roupas, ornamentação de flores artesanal
    Higienização do corpo, tanotopraxia, cova simples no cemitério local, 
    translado de 300 km (150 ida e 150 volta).`,
    convenience: `100 lembrancinhas, 
      visita de 7º dia, 
      comodidade em todo o período do evento fúnebre,
      de acordo ao credo religioso.`,
    valor: 0,
    background: '#ffff00',
    color: '#232323',
  },
  {
    _id: 5,
    description: 'Plano Master',
    benefits: `Cobertura de 30% em consultas e exames, 
      4 dias gratuitos em pensão em Teresina/PI.`,
    beneficiares: `
      Esposa(o), filhos solteiros, dois dependentes economicamente sendo 
      eles pai e mãe, sogro(a) ou netos.`,
    service: `Urna mortuária Luxo,
    conjunto completo de roupas, ornamentação de flores artesanal
    Higienização do corpo, tanotopraxia, cova simples no cemitério local, 
    translado de 300 km (150 ida e 150 volta).
    `,
    convenience: `100 lembrancinhas, 
      visita de 7º dia, 
      comodidade em todo o período do evento fúnebre, de acordo ao credo religioso,
      Coroa de Flores e café da manhã.`,
    valor: 0,
    background: '#872D46',
    color: '#fff',
  },
]
