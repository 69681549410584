export const apiEmail = {
  enviar: async (nome, email, msg) => {
    const contato = {
      nome,
      email,
      msg,
    }

    const requisicao = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(contato),
    }

    const response = await fetch(
      'https://funerariapamf.com.br/api-email/',
      requisicao
    )
    const data = await response.json()

    return data
  },
}

export default apiEmail